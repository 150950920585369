hr { 
    background: url('../images/brain_icon.png') no-repeat top center;
    background-size: contain;
    display: block;
    height: 35px;
    border: 0;
    position: relative;
}
hr:before,
hr:after {
    content: '';
    display: block;
    position: absolute;
    background: #d7d7d7;
    height: 2px;
    top: 16px;
}
hr:before {
    left: 0;
    right: 50%;
    margin-right: 10px;
}
hr:after {
    right: 0;
    left: 50%;
    margin-left: 10px;
}